import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AppConfigService, AuthenticationService, StorageService } from '@app/services';
import { Session } from '@app/shared';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements AfterViewInit, OnInit {
  title = AppConfigService.settings.titulo;
  currentUser!: Session | null;
  isLogged = false;

  constructor(
    private titleService: Title,
    private authenticationService: AuthenticationService,
    private storageService: StorageService,
    private router: Router
  ) {
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
  }

  ngAfterViewInit() {
    this.titleService.setTitle(AppConfigService.settings.titulo);
    setTimeout(() => {
      this.authenticationService.statusChanged.subscribe(isLogin => {
        this.isLogged = isLogin;
      });
    });
  }

  ngOnInit(){
    this.isLogged = this.storageService.isAuthenticated();
  }

  logout() {
    this.isLogged = false;
    this.authenticationService.logout();
    this.router.navigate(['/login']);
  }

}
