import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { AppRoutingModule } from '@app/app-routing.module';
import { AppComponent } from '@app/app.component';
import { CookieService } from 'ngx-cookie-service';

// Material
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatAccordion } from "@angular/material/expansion";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from "@angular/material/list";
import { MatNativeDateModule } from '@angular/material/core';
import { MatSidenavModule } from '@angular/material/sidenav';

// Core
import { ErrorInterceptor, JwtInterceptor, LoginComponent, NavigationComponent } from '@app/core';

// Shared
import { CommonDialogComponent } from '@app/shared/components/common-dialog/common-dialog.component';
import { ModalLoadmeComponent } from '@app/shared/components/modal-loadme/modal-loadme.component';
import { ModalYesNoComponent } from './shared/components/modal-yes-no/modal-yes-no.component';
import { ModalSeccionBloqueadaComponent } from '@app/shared/components/modal-seccion-bloqueada/modal-seccion-bloqueada.component';
import { ModalImageViewerComponent } from './shared/components/modal-image-viewer/modal-image-viewer.component';

// Services
import { AppConfigService, RestApiService, StorageService } from '@app/services';

export function initializeApp(appConfig: AppConfigService) {
    return () => appConfig.load();
}

@NgModule({ declarations: [
        AppComponent,
        LoginComponent,
        NavigationComponent,
        CommonDialogComponent,
        ModalLoadmeComponent,
        ModalYesNoComponent,
        ModalSeccionBloqueadaComponent,
        ModalImageViewerComponent
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    imports: [FormsModule,
        AppRoutingModule,
        BrowserModule,
        BrowserAnimationsModule,
        ReactiveFormsModule,
        MatAccordion,
        MatButtonModule,
        MatDialogModule,
        MatExpansionModule,
        MatIconModule,
        MatListModule,
        MatNativeDateModule,
        MatSidenavModule
    ],
    providers: [
        provideAnimationsAsync(),
        { provide: APP_INITIALIZER, useFactory: initializeApp, deps: [AppConfigService], multi: true },
        { provide: MAT_DATE_LOCALE, useValue: "es-AR" },
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        CookieService,
        AppConfigService,
        StorageService,
        RestApiService,
        provideHttpClient(withInterceptorsFromDi()),
    ]
})
export class AppModule {}
