<mat-sidenav-container class="example-container" [class.nav-is-mobile]="mobileQuery.matches" fullscreen>
	<mat-sidenav #drawer [mode]="mobileQuery.matches ? 'over' : 'side'" [fixedInViewport]="mobileQuery.matches" role="navigation" [(opened)]="opened">
		<mat-list-item class="logo-icons">
			<img class="logo-int" src="img/logo-esco-sections.svg" alt="ESCO S.A." />
			<mat-list-item
				class="end"
				type="button"
				routerLink="/configuracion"
				title="Configuración de cuenta"
				style="background-color: #012169; margin-right: 3px"
			>
				<mat-icon class="fas fa-user-cog"></mat-icon>
			</mat-list-item>
			<mat-list-item class="end" type="button" id="logout" (click)="logOut()" title="Salir" style="background-color: #09183a !important">
				<mat-icon class="fa fa-power-off"></mat-icon>
			</mat-list-item>
		</mat-list-item>
		<mat-list-item class="mt-2" role="button" routerLink="/notificaciones">
			<mat-icon class="fas fa-comment-alt"></mat-icon>
			<span class="menu-text">Notificaciones</span>
			<span class="badge badge-light">{{ unReadedNotifications }}</span>
		</mat-list-item>
		<mat-list-item role="button" routerLink="/imprimir-boletas">
			<mat-icon class="fas fa-file-alt"></mat-icon>
			<span class="menu-text">Imprimir boletas</span>
		</mat-list-item>
		<mat-list-item role="button" routerLink="/archivos">
			<mat-icon class="fas fa-file"></mat-icon>
			<span class="menu-text">Descargar archivos</span>
		</mat-list-item>
		<mat-list-item role="button" routerLink="/debitos">
			<mat-icon class="fas fa-credit-card"></mat-icon>
			<span class="menu-text">Adhesión débito</span>
		</mat-list-item>
		<mat-list-item role="button" routerLink="/padrones-ampliacion">
			<mat-icon class="fas fa-list-ol"></mat-icon>
			<span class="menu-text">Titulares a ampliar</span>
		</mat-list-item>
		<mat-list-item role="button" routerLink="/consulta-rescate">
			<mat-icon class="fas fa-money-check-alt"></mat-icon>
			<span class="menu-text">Rescate y Anticipos</span>
		</mat-list-item>
		<mat-list-item role="button" routerLink="/produccion-actual">
			<mat-icon class="fas fa-chart-line"></mat-icon>
			<span class="menu-text">Produccion Actual</span>
		</mat-list-item>
		<mat-list-item role="button" routerLink="/lista-precios">
			<mat-icon class="fas fa-list-alt"></mat-icon>
			<span class="menu-text">Lista Precios / Catálogo</span>
		</mat-list-item>
		<mat-list-item role="button" routerLink="/contactos">
			<mat-icon class="far fa-address-card"></mat-icon>
			<span class="menu-text">Contactos</span>
		</mat-list-item>
		<mat-list-item role="button" routerLink="/canje-puntos-productores">
			<mat-icon class="fas fa-gifts"></mat-icon>
			<span class="menu-text">Canje de puntos de Productores</span>
		</mat-list-item>
		<mat-list-item role="button" routerLink="/solicitud-ploteo">
			<mat-icon class="fas fa-palette"></mat-icon>
			<span class="menu-text">Solicitudes de material gráfico</span>
		</mat-list-item>
		<mat-accordion class="mb-3">
			<mat-expansion-panel>
				<mat-expansion-panel-header>
					<mat-panel-title>
						<mat-icon class="fas fa-receipt"></mat-icon>
						<span class="menu-text">Carga de comprobantes</span>
					</mat-panel-title>
				</mat-expansion-panel-header>
				<mat-list-item role="button" [routerLink]="['/comprobantes-pago/']" [queryParams]="{ tipo: 'D' }">
					<span class="menu-text">Transferencias/depósitos</span>
				</mat-list-item>
				<mat-list-item role="button" [routerLink]="['/comprobantes-pago/']" [queryParams]="{ tipo: 'A' }">
					<span class="menu-text">Acreditación de cuotas</span>
				</mat-list-item>
			</mat-expansion-panel>
			<mat-expansion-panel>
				<mat-expansion-panel-header>
					<mat-panel-title>
						<mat-icon class="fas fa-user-check"></mat-icon>
						<span class="menu-text">Adjudicados</span>
					</mat-panel-title>
				</mat-expansion-panel-header>
				<mat-list-item role="button" routerLink="/ultimo-sorteo">
					<mat-icon class="fas fa-history"></mat-icon>
					<span class="menu-text">Último Sorteo</span>
				</mat-list-item>
				<mat-list-item role="button" routerLink="/historico-adjudic">
					<mat-icon class="fas fa-users"></mat-icon>
					<span class="menu-text">Historial Adjudicados</span>
				</mat-list-item>
			</mat-expansion-panel>
			<mat-expansion-panel>
				<mat-expansion-panel-header>
					<mat-panel-title>
						<mat-icon class="fas fa-tshirt"></mat-icon>
						<span class="menu-text">Alentando pasiones</span>
					</mat-panel-title>
				</mat-expansion-panel-header>
				<mat-list-item role="button" [routerLink]="['/camisetas/']" [queryParams]="{ tipo: 'solicitud' }">
					<span class="menu-text">Solicitud camisetas</span>
				</mat-list-item>
				<mat-list-item role="button" [routerLink]="['/camisetas/']" [queryParams]="{ tipo: 'sorteo' }">
					<span class="menu-text">Sorteo camisetas</span>
				</mat-list-item>
			</mat-expansion-panel>
			<mat-expansion-panel>
				<mat-expansion-panel-header>
					<mat-panel-title>
						<mat-icon class="fas fa-balance-scale"></mat-icon>
						<span class="menu-text">Gastos</span>
					</mat-panel-title>
				</mat-expansion-panel-header>
				<mat-list-item role="button" [routerLink]="['/gastos/']" [queryParams]="{ tipo: 'ordinarios' }">
					<span class="menu-text">Gastos ordinarios</span>
				</mat-list-item>
				<mat-list-item role="button" [routerLink]="['/gastos/']" [queryParams]="{ tipo: 'extraordinarios' }">
					<span class="menu-text">Gastos extraordinarios</span>
				</mat-list-item>
			</mat-expansion-panel>
		</mat-accordion>
	</mat-sidenav>

	<mat-sidenav-content>
		<a mat-button (click)="drawer.toggle()" (keypress)="drawer.toggle()" tabindex="0">
			<i class="fas fa-bars"></i>
		</a>
		<router-outlet></router-outlet>
	</mat-sidenav-content>
</mat-sidenav-container>
