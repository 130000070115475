import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from '@app/services';

@Component({
  selector: 'app-login',
  styleUrls: ['login.component.css'],
  templateUrl: 'login.component.html'
})
export class LoginComponent {
    loginForm: UntypedFormGroup = this.formBuilder.group({
        username: ['', Validators['required']],
        password: ['', Validators['required']]
    });
    loading = false;
    submitted = false;
    error = '';
    // get return url from route parameters or default to '/'
    returnUrl: string = this.route.snapshot.queryParams['returnUrl'] || '/imprimir-boletas';

    constructor(
        private formBuilder: UntypedFormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService,
    ) {
        // redirect to home if already logged in
        if (this.authenticationService.currentUserValue) {
            this.router.navigate(['/imprimir-boletas']);
        }
    }

    // convenience getter for easy access to form fields
    get f() { return this.loginForm.controls; }

    onSubmit() {
        this.submitted = true;

        // stop here if form is invalid
        if (this.loginForm.invalid) {
            return;
        }

        this.loading = true;
        this.authenticationService.login(this.f['username'].value, this.f['password'].value).subscribe({
            next: (data: any) => {
                this.authenticationService.cambiaLogin(true);
                this.router.navigate([this.returnUrl]);
            },
            error: (error: any) => {
                switch (error.status) {
                    case 401:
                        this.error = 'Verifique el usuario o contraseña ingresada';
                        break;
                    case 404:
                        this.error = 'La solicitud no puede ser cursada';
                        break;
                    case 500:
                        this.error = 'Ocurrió un error con el servidor';
                        break;
                    default:
                        this.error = 'Error genérico';
                }
                this.loading = false;
            }
        });
    }
}
