import { AfterViewInit, Component, ChangeDetectorRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AuthenticationService } from '@app/services';
import { RestApiService } from '@app/services';
import { Router } from '@angular/router';
import { MenuItem, MenuContainer } from '@app/shared';
import { MediaMatcher } from '@angular/cdk/layout';
import { MatSidenav } from '@angular/material/sidenav';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css'],
  styles: [
    `
      ::ng-deep .mat-expansion-indicator:after {
        color: white !important;
      }
    `,
  ],
})
export class NavigationComponent implements AfterViewInit, OnInit, OnDestroy {
  @ViewChild('drawer') drawer!: MatSidenav;
  opened: boolean = true;
  public isLogin = true;
  public menuItems: MenuContainer[];
  public unReadedNotifications: number = 0;

  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;

  constructor(
    private authserv: AuthenticationService,
    private restApi: RestApiService,
    private router: Router,
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher
  ) {
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addEventListener('change', this._mobileQueryListener);
    this.menuItems = new Array();
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeEventListener('change', this._mobileQueryListener);
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.authserv.statusChanged.subscribe(isLogin => {
        this.isLogin = isLogin;
      });
    });
  }

  ngOnInit() {
    // this.fetchMenuItems();
    this.fetchUnreadNotifications();
  }

  fetchMenuItems() {
    this.restApi.getMenuList().then(data => {
      let items: MenuItem[];
      items = JSON.parse(JSON.stringify(data['hydra:member']));
      let grupo = 0;
      let it = new Array<MenuItem>();
      items.forEach(element => {
        if (grupo > 0 && element.gsid != grupo) {
          let cont = new MenuContainer(it[0].grupo, it);
          this.menuItems.push(cont);
          grupo = element.gsid;
          it = new Array<MenuItem>();
        } else {
          if (grupo == 0) {
            grupo = element.gsid;
          }
        }
        it.push(element);
      });
      let cont = new MenuContainer(it[0].grupo, it);
      this.menuItems.push(cont);
    });
  }

  fetchUnreadNotifications() {
    this.restApi.getUnreadedNotifications().then(data => {
      this.unReadedNotifications = data.count;
    });
  }

  /**
   * Cierra la sesión y redirige a la pantalla de login
   */
  logOut() {
    this.authserv.logout();
    this.authserv.cambiaLogin(false);
    this.router.navigate(['/login']);
  }

  toggleDrawer() {
    this.drawer.toggle();
  }
}
