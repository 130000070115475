<header>
	<img class="logo-home" src="img/logo-esco-adjudicado.svg" alt="ESCO S.A." />
	<nav id="nav" class="navbar navbar-dark navbar-expand-md bg-faded">
		<a class="navbar-brand" href="#"><img src="img/logo-esco-01.svg" alt="ESCO S.A." /></a>
		<div class="collapse navbar-collapse w-100" id="navbarSupportedContent">
			<h1>EXCLUSIVO PARA AGENCIAS</h1>
		</div>
	</nav>
</header>
<div class="container">
	<div class="row justify-content-center">
		<div class="col-lg-4 col-md-12 py-5">
			<form class="form-row d-flex flex-column" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
				<div class="input-group">
					<div class="input-group-prepend">
						<span class="input-group-text" id="inputGroupPrepend"><i class="fa fa-user prefix"></i></span>
					</div>
					<input
						type="text"
						formControlName="username"
						class="form-control"
						placeholder="Usuario"
						autocomplete="username"
						[ngClass]="{ 'is-invalid': submitted && f['username'].errors }"
					/>
					<div *ngIf="submitted && f['username'].errors" class="invalid-feedback">
						<div *ngIf="f['username'].errors['required']">Username is required</div>
					</div>
				</div>
				<br />
				<div class="input-group">
					<div class="input-group-prepend">
						<span class="input-group-text" id="inputGroupPrepend"><i class="fa fa-lock prefix"></i></span>
					</div>
					<input
						type="password"
						formControlName="password"
						class="form-control"
						placeholder="Contraseña"
						autocomplete="current-password"
						[ngClass]="{ 'is-invalid': submitted && f['password'].errors }"
					/>
					<div *ngIf="submitted && f['password'].errors" class="invalid-feedback">
						<div *ngIf="f['password'].errors['required']">Password is required</div>
					</div>
				</div>
				<br />
				<button [disabled]="loading" class="btn btn-primary">
					<span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>Entrar
				</button>
				<div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{ error }}</div>
			</form>
		</div>
	</div>
</div>
<footer>
	<div class="container-fluid legals">
		<div class="row">
			<div class="col-10 col-md-5">
				<p>
					<span class="icon-location-icon"></span><a href="https://goo.gl/maps/vhrHF1sN1Q32" target="_blank">Gualeguaychú 763.</a><br />
					<span>Paraná, Entre Ríos, Argentina</span>
				</p>
				<p><span class="icon-phone-icon"></span> (0343) 4230398</p>
				<br />
				<p><span class="icon-icon-whatsapp"></span> 3434566284</p>
				<br />
			</div>
			<div class="col-10 col-md-5">
				<a href="https://www.esco.com.ar" target="_blank"><span class="icon-web-icon"></span> www.esco.com.ar</a>
				<br />
				<a href="https://www.facebook.com/ESCOSAoficial/" target="_blank"><span class="icon-face-icon-footer"></span> ESCO S.A. Oficial</a>
				<br />
				<a href="https://www.instagram.com/escosaoficial/" target="_blank"><span class="icon-instagram-icon-footer"></span> escosaoficial</a>
				<br />
				<a href="https://www.youtube.com/user/escocapyahorro" target="_blank"><span class="icon-youtube-icon-footer"></span> ESCO S.A.</a>
			</div>
		</div>
	</div>
</footer>
