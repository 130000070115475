<div class="modal-dialog modal-notify modal-info">
	<div class="modal-content">
		<div class="modal-header">
			<h1 class="heading lead">{{ title }}</h1>
			<button [mat-dialog-close] type="button" class="close" data-dismiss="modal" aria-label="Close">
				<span aria-hidden="true" class="text-white">&times;</span>
			</button>
		</div>
		<div mat-dialog-content class="modal-body">
			<div class="text-center">
				<i class="fas fa-question fa-2x animated rotateIn mb-4"></i>
				<p>{{ message }}</p>
			</div>
			<div class="text-center">
				<a type="button" (click)="onYes($event)" (keypress)="onYes($event)" class="btn btn-success" tabindex="0"
					><i class="fas fa-check fa-lg mr-1 text-white animated rotateIn"></i> SI</a
				>
				<a type="button" (click)="onNo($event)" (keypress)="onNo($event)" class="btn btn-danger" data-dismiss="modal" tabindex="0"
					><i class="far fa-window-close fa-lg mr-1 text-white"></i> NO</a
				>
			</div>
		</div>
	</div>
</div>
