import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-yes-no',
  templateUrl: './modal-yes-no.component.html',
  styleUrls: ['./modal-yes-no.component.css'],
  styles: [`
    ::ng-deep .mat-dialog-container{
      padding: 0 !important;
      overflow: hidden;
    }
  `]
})
export class ModalYesNoComponent {

  public title: string;
  public message: string;

  constructor(private dialogRef: MatDialogRef<ModalYesNoComponent>, @Inject(MAT_DIALOG_DATA) data: any) {
    this.title = data.title;
    this.message = data.message
   }

  onNo(event: any){
    event.preventDefault();
    event.stopPropagation();
    this.dialogRef.close(false);
  }

  onYes(event: any){
    event.preventDefault();
    event.stopPropagation();
    this.dialogRef.close(true);
  }

}
