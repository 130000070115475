import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { OverlayContainer } from '@angular/cdk/overlay';
import { ModalSeccionBloqueadaComponent } from '@app/shared/components/modal-seccion-bloqueada/modal-seccion-bloqueada.component';
import { AuthenticationService, RestApiService, AppConfigService } from '@app/services';
import { Secciones } from '@app/shared';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  private numBloqueo: number;
  private passBloqueo: string;
  private passConfigurada: boolean;
  private seccionesBloqueadas: any;

  constructor(
    private router: Router,
    private appConfigService: AppConfigService,
    private authenticationService: AuthenticationService,
    private restApiService: RestApiService,
    private dialog: MatDialog,
    private overlayContainer: OverlayContainer,
  ) { }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    await this.restApiService.getDataAgencia().then(async (data: any) => {
      this.numBloqueo = data['numBloqueo'];
      this.passBloqueo = data['passwordBloqueo'];
      this.passConfigurada = this.passBloqueo ? true : false;
      this.seccionesBloqueadas = this.appConfigService.seccionesBloqueadas(this.numBloqueo);
      if (this.seccionesBloqueadas.find((seccion: Secciones) => seccion.ruta === state.url && seccion.estado === true) && this.passConfigurada && localStorage.getItem("development") == undefined) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = false;
        dialogConfig.data = this.passBloqueo;
        this.overlayContainer.getContainerElement().classList.add('custom-overlay-backdrop');
        const dialogRef = this.dialog.open(ModalSeccionBloqueadaComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(result => {
          this.overlayContainer.getContainerElement().classList.remove('custom-overlay-backdrop');
          if (result) {
            return true;
          } else {
            this.router.navigate(['/configuracion']);
            return false;
          }
        });
      }
    });

    const currentUser = this.authenticationService.currentUserValue;
    if (currentUser) {
      // authorised so return true
      return true;
    }

    // not logged in so redirect to login page with the return url
    this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
    return false;
  }
}
