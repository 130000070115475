import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { AuthGuard, LoginComponent } from '@app/core';

const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'archivos', loadChildren: () => import('./modules/archivos/archivos.module').then(m => m.ArchivosModule), canActivate: [AuthGuard] },
  { path: 'lista-precios', loadChildren: () => import('./modules/lista-precios/lista-precios.module').then(m => m.ListaPreciosModule), canActivate: [AuthGuard] },
  { path: 'configuracion', loadChildren: () => import('./modules/configuracion-agencia/configuracion-agencia.module').then(m => m.ConfiguracionAgenciaModule), canActivate: [AuthGuard] },
  { path: 'imprimir-boletas', loadChildren: () => import('./modules/boletas/boletas.module').then(m => m.BoletasModule), canActivate: [AuthGuard] },
  { path: 'padrones-ampliacion', loadChildren: () => import('./modules/padrones-ampliacion/padrones-ampliacion.module').then(m => m.PadronesAmpliacionModule), canActivate: [AuthGuard] },
  { path: 'comprobantes-pago', loadChildren: () => import('./modules/comprobantes-deposito/comprobantes-deposito.module').then(m => m.ComprobantesDepositoModule), canActivate: [AuthGuard] },
  { path: 'produccion-actual', loadChildren: () => import('./modules/produccion-actual/produccion-actual.module').then(m => m.ProduccionActualModule), canActivate: [AuthGuard] },
  { path: 'consulta-rescate', loadChildren: () => import('./modules/consulta-rescate/consulta-rescate.module').then(m => m.ConsultaRescateModule), canActivate: [AuthGuard] },
  { path: 'debitos', loadChildren: () => import('./modules/debitos/debitos.module').then(m => m.DebitosModule), canActivate: [AuthGuard] },
  { path: 'ultimo-sorteo', loadChildren: () => import('./modules/adjudicados/ultimo-sorteo/ultimo-sorteo.module').then(m => m.UltimoSorteoModule), canActivate: [AuthGuard] },
  { path: 'historico-adjudic', loadChildren: () => import('./modules/adjudicados/historico-adjudic/historico-adjudic.module').then(m => m.HistoricoAdjudicModule), canActivate: [AuthGuard] },
  { path: 'contactos', loadChildren: () => import('./modules/contactos/contactos.module').then(m => m.ContactosModule), canActivate: [AuthGuard] },
  { path: 'notificaciones', loadChildren: () => import('./modules/notificaciones/notificaciones.module').then(m => m.NotificacionesModule), canActivate: [AuthGuard] },
  { path: 'camisetas', loadChildren: () => import('./modules/camisetas/camisetas.module').then(m => m.CamisetasModule), canActivate: [AuthGuard] },
  { path: 'solicitud-ploteo', loadChildren: () => import('./modules/ploteos/ploteos.module').then(m => m.PloteosModule), canActivate: [AuthGuard] },
  { path: 'canje-puntos-productores', loadChildren: () => import('./modules/canje-puntos-productores/canje-puntos-productores.module').then(m => m.CanjePuntosProductoresModule), canActivate: [AuthGuard] },
  { path: 'gastos', loadChildren: () => import('./modules/gastos/gastos.module').then(m => m.GastosModule), canActivate: [AuthGuard] },
  { path: '#', redirectTo: '#', pathMatch: 'full' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      scrollPositionRestoration: 'enabled' 
    })
  ],
  exports: [RouterModule]
})

export class AppRoutingModule { }
